<script>
// Inspiration :: https://stackoverflow.com/questions/60928670/vue-chartjs-doughnut-chart-with-text-in-the-middle
import { Doughnut } from "vue-chartjs";
import Chart from "chart.js";

export default {
  extends: Doughnut,
  props: {
    showData: { type: Object, required: false, default: () => null },
  },
  mounted() {
    Chart.Tooltip.positioners.cursor = function (chartElements, coordinates) {
      return coordinates;
    };
    this.addPlugin({
      id: "add-text",
      beforeDraw: function (chart) {
        const ctx = chart.chart.ctx;
        const width = chart.chart.width;
        const height = chart.chart.height;
        const lineHeight = 18;
        ctx.restore();

        ctx.font = `${(height / 150).toFixed(2)}em sans-serif`;
        ctx.textBaseline = "middle";
        let text = chart.config.data.totalData || 0;
        let textX = Math.round((width - ctx.measureText(text).width - chart.legend.width) / 2);
        let textY = Math.round(height / 2 - lineHeight);
        ctx.fillText(text, textX, textY);

        ctx.font = `${(height / 270).toFixed(2)}em sans-serif`;
        ctx.textBaseline = "middle";
        text = chart.config.data.label || "";
        textX = Math.round((width - ctx.measureText(text).width - chart.legend.width) / 2);
        textY = Math.round(height / 2 + lineHeight);
        ctx.fillText(text, textX, textY);

        ctx.save();
      },
    });

    this.renderChart(this.showData, {
      legend: {
        display: true,
        position: "right",
        align: "center",
        labels: {
          boxWidth: 40,
          fontSize: 12,
          padding: 12,
          fontColor: "#444",
          generateLabels: function (chart) {
            let meta = chart.getDatasetMeta(0);
            let totalData = chart.data.totalData;
            if (chart.data.labels.length && chart.data.datasets.length) {
              return chart.data.labels.map(function (label, i) {
                let style = meta.controller.getStyle(i);
                let data = chart.data.datasets[0].data[i];
                return {
                  text: `${totalData ? data : 0} ${label}`,
                  fillStyle: style.backgroundColor,
                  // strokeStyle: style.borderColor,
                  // lineWidth: 0,
                  hidden: !data || meta.data[i].hidden,
                  // Extra data used for toggling the correct item
                  index: i,
                };
              });
            }
            return [];
          },
          // Prevent items with undefined labels from appearing in the legend
          filter: (item) => !!item.text,
        },
      },
      tooltips: {
        mode: "label",
        position: "cursor",
        intersect: true,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.labels[tooltipItem.index] || "";
            var count = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || "0";
            return `${label}: ${data.totalData ? count : 0}`;
          },
        },
        // Prevent items with undefined labels from showing tooltips
        filter: (item, chart) => !!chart.labels[item.index],
      },
      cutoutPercentage: 80,
      animation: {
        animateScale: true,
        duration: 0,
      },
      responsive: true,
      maintainAspectRatio: false,
    });
  },

  methods: {
    // textCenter(val) {}
  },
};
</script>

<style scoped></style>
